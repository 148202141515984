import "../articles/articlePost.css";
import Layout from "../core/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageTitle from "../core/PageTitle";
import parse from "html-react-parser";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import FeaturedJobsSection from "../jobs/FeaturedJobsSection";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import MakerOSSection from "../ads/MakerOSSection";
import SimilarContentRow from "../../tailwindComponents/SimilarContentRow";
import { Divider } from "@material-ui/core";
import { shuffleArray } from "../../utils/ArrayUtils";

interface SnippetPostProps extends RouteComponentProps {
  pageContext: {
    data: any;
    featuredJobs: any[];
    otherSnippets: any[];
  };
}

export default function SnippetPost(props: SnippetPostProps) {
  const { frontmatter, body } = props.pageContext.data;
  const pageTitle = frontmatter.name;
  const classes = useStyles();
  const gistUrlArray = frontmatter.gist.split("/");
  const gistId = gistUrlArray[gistUrlArray.length - 1];
  const [randomSnippets, setRandomSnippets] = useState<any[]>([]);
  useEffect(() => {
    setRandomSnippets(
      shuffleArray(props.pageContext.otherSnippets).slice(0, 3)
    );
  }, []);

  return (
    <>
      <Layout
        maxWidth="md"
        pageTitle={"Compose Snippets: " + pageTitle}
        pageDescription={frontmatter.description}
        pageSlug={`${frontmatter.slug}`}
        pageImageUrl="/snippets_seo_poster.png"
        seoImageUrl="/snippets_seo_poster.png"
      >
        <div className={classes.layoutContainer}>
          <PageTitle header={pageTitle} subheader="" />
          <div className={classes.authorRow}>
            Author:{" "}
            <a href={frontmatter.authorProfileUrl} target="__blank">
              <span className={classes.authorName}>
                {frontmatter.authorName}
              </span>
            </a>
          </div>
          <div className="articlePostContainer">
            <MDXRenderer
              className="blog-post-content"
              frontmatter={frontmatter}
              twitterShareUrl=""
            >
              {body}
            </MDXRenderer>
          </div>
          <div className={classes.formSubmission}>
            Have a project you'd like to submit?{" "}
            <OutboundLink
              href="https://airtable.com/shr7HuMcSTnfgfFjd"
              target="_blank"
              className={classes.formSubmissionLink}
            >
              Fill this form
            </OutboundLink>
            , will ya!
          </div>
        </div>
        <Divider className="divider" />
        <SimilarContentRow
          content={randomSnippets}
          title="If you like this snippet, you might also like:"
          columnSize="three"
        />
        <div className={classes.jobs}>
          <MakerOSSection />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  description: {
    fontSize: 20,
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    color: "#222222",
  },
  layoutContainer: {
    marginBottom: 32,
    padding: 12,
  },
  authorRow: {
    fontSize: 18,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    color: "#444444",
    marginTop: 16,
    marginBottom: 16,
  },
  authorName: {
    backgroundColor: "lightgoldenrodyellow",
    padding: 6,
    color: "#444444",
  },
  gist: {
    // marginBottom: 32,
    marginTop: 32,
  },
  jobs: {
    marginTop: 32,
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    padding: 16,
  },
  formSubmission: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Inter",
    color: "#222222",
  },
  formSubmissionLink: {
    color: "#4636f7",
  },
});
